#lrs-corp-bar {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 0.6875rem;
  border-bottom: 3px solid #999999;
  background: #b3b3b3;
  color: #1a1a1a;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75rem;
  font-family: $primary-font;
  line-height: 0.75rem;

  @media only screen and (max-width: 1023px) {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 100%;
  }

  @media only screen and (min-width: 40.063em) {
    padding: 0.6875rem 1.563rem;
  }

  .title {
    display: none;
    float: left;

    @media only screen and (min-width: 40.063em) {
      display: inline-block;
    }
  }

  .sites {
    width: 100%;

    @media only screen and (min-width: 40.063em) {
      width: auto;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.75rem;

      @media only screen and (min-width: 40.063em) {
        text-align: right;
      }

      li {
        display: inline-block;
        margin: 0 0.75rem;

        @media only screen and (min-width: 40.063em) {
          margin: 0 0 0 1.5rem;
        }

        &:after {
          position: relative;
          left: 0.63rem;
          color: gray;
          content: '|';
          font-weight: normal;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &:last-child:after {
          display: none;
        }

        a {
          color: #404040;
          text-decoration: none;
          -webkit-transition: color 0.25s ease-in-out;
             -moz-transition: color 0.25s ease-in-out;
              -ms-transition: color 0.25s ease-in-out;
               -o-transition: color 0.25s ease-in-out;
                  transition: color 0.25s ease-in-out;

          &:hover,
          &:focus {
            color: white;
          }
        }
      }
    }
  }
}




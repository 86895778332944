section.mobile-header,
.mobile-header {
  width: 100%;

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;
    overflow: scroll;

// min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #091b36;
    font-weight: 400;
    font-style: normal;

    // &::before {
    //   position: absolute;
    //   top: 150px;
    //   right: 0;
    //   z-index: 160;
    //   overflow-x: hidden;
    //   min-height: 100vh;
    //   width: 100%;
    //   background: #091b36;
    //   content: '';
    // }

    .mobile-wrapper {
      position: absolute;
      right: 0;
      z-index: 160;

// overflow-x: hidden;
      margin-top: 170px;
      min-height: 100%;
      width: 100%;
    }

    .ul1 {
      display: block;
      margin-top: 100px;
      padding: 0 40px 0;
      background: transparent;
      list-style: none;

      .li1 {
        display: block;
        padding-bottom: 30px;
        color: #17468f;

        &:last-child {
          padding-bottom: 0;
        }

        // &:after {
        //   position: absolute;
        //   left: 47px;
        // }

        .a1 {
          position: relative;
          padding-left: 40px;
          color: #ffffff;
          vertical-align: middle;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-size: 12px;

          &:before {
            position: absolute;
            margin-left: -30px;
            color: #80dfff;
            font-size: 15px;
            font-family: FontAwesome;
          }
        }

        .ul2 {
          margin-left: 50px;
          padding: 0;
          list-style: none;

          .li2 {
            margin-left: -40px;
            padding-left: 50px;
            font-weight: 300;
            font-size: 12px;
            line-height: 1.0625rem;

            &:first-child {
              margin-top: 10px;
            }

            &:last-child {
              border-bottom: 1px dotted #17468f;
            }

            .a2 {
              display: block;
              padding: 15px 0;
              border-bottom: none;
              color: #cddc28;
              text-decoration: none;
              text-transform: uppercase;
              letter-spacing: 2px;
            }
          }
        }
      }

      .li1#about-us-mobile .a1:before {
        content: '\f2bd';
      }

      .li1#software-mobile .a1:before {
        content: '\f108';
      }

      .li1#implementation-mobile .a1:before {
        content: '\f013';
      }

      .li1#support-mobile .a1:before {
        content: '\f095';
      }

      .li1#news-mobile .a1:before {
        content: '\f1ea';
      }

      .li1#testimonials-mobile .a1:before {
        content: '\f10d';
      }
    }

    .ul2.is_child .li2 {
      display: none;
    }

    .ul2.is_child .li2.show {
      display: block;
    }

    .li1.has_children {
      // cursor: pointer;

      &:focus {
        outline: none;
      }

      // &:before {
      //   position: relative;
      //   float: right;
      //   color: #80dfff;
      //   content: '\f055';
      //   font-weight: 100;
      //   font-size: 18px;
      //   font-family: FontAwesome;
      //   opacity: 0.25;
      // }

      // &.minus:before {
      //   display: inline-block;
      //   color: #80dfff;
      //   content: '\f056';
      //   text-decoration: none;
      //   font-weight: 100;
      //   font-size: 18px;
      //   font-family: FontAwesome;
      //   opacity: 0.25;
      // }

      span {
        position: relative;
        float: right;
        color: #80dfff;
        font-weight: 100;
        font-size: 18px;
        opacity: 0.25;
        cursor: pointer;

        span {
          opacity: 1;
        }
      }
    }

    .secondary-nav {
      position: relative;
      top: 2px;
      float: right;

      @media only screen and (max-width: 767px) {
        display: none;
      }

      ul {
        position: relative;
        display: inline-flex;
        margin: 0 auto;
        padding: 0;
        list-style: none;

        li {
          position: relative;
          top: 45px;
          display: inline-block;
          float: right;
          padding: 0 20px 0 5px;
          color: #333333;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 11px;

          &:first-child {
            padding-right: 15px;
            padding-left: 0;
          }

          a {
            color: #333333;
            text-decoration: none;

            &:hover {
              border-bottom: 3px solid #ffd552;
            }
          }

          span.search-btn {
            position: relative;
            top: -8px;
          }

          .fa.fa-search {
            position: relative;
            top: -4px;
            padding-right: 10px;
            font-weight: 700;
            font-size: 22px;
          }
        }
      }
    }
  }
}

.btn-mobile-open-nav {
  position: relative;
  top: 45px;
  display: inline-block;
  float: right;
  overflow: hidden;
  margin-right: 20px;
  color: #17468f;
  font-size: 1.5rem;
  font-size: 26px;
  line-height: 0.0625rem;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    position: absolute;
    top: 85px;
    right: 0;
    z-index: 2;
  }

  span {
    position: relative;
    top: 0;
    display: inline-block;
  }

  span.menu-open {
    width: 30px;
    height: 20px;
    background-image: url('/themes/RetirementSolutions/assets/dist/images/ICO - Menu.png');
    background-repeat: no-repeat;
    content: '';
  }

  span.menu-close {
    width: 30px;
    height: 23px;
    background-image: url('/themes/RetirementSolutions/assets/dist/images/ICO - Close.png');
    background-repeat: no-repeat;
    content: '';
  }
}

.btn-mobile-close-nav {
  display: block;
  padding: 45px 35px 22px;
  background: #ffffff;
  color: #17468f;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
  font-size: 26px;
  line-height: 26px;
  cursor: pointer;

  @media only screen and (max-width: 320px) {
    padding: 50px 20px;
  }

  span {
    position: relative;
    top: -7px;
    margin-left: 0.4rem;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
  }

  span.menu {
    position: absolute;
    top: 40px;
    left: 25px;
    text-transform: none;
    font-weight: 500;
    font-style: normal;
    font-size: 36px;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute!important;
  overflow: hidden;
  clip: rect(0,0,0,0);
  width: 1px;
  height: 1px;
}

.fa-close:before {
  color: #12a8e0;
  content: '\f00d';
}

.fixed {
  @media only screen and (max-width: 1023px) {
    position: fixed !important;
  }
}

html {
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  font-size: 100%;
}

body.homepage {
  position: relative;
  -webkit-overflow-scrolling: touch;
  min-height: 100%;
  background-color: #0e203a;
  background-image: url('/Themes/RetirementSolutions/assets/dist/images/hp-background.jpg');
  background-attachment: fixed;
  background-position: 30% top;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 100%;

  &:before {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    content: '';
  }
}

body.subpage {
  position: relative;
  -webkit-overflow-scrolling: touch;
  min-height: 100%;
  background-color: #0e203a;
  background-image: url('/Themes/RetirementSolutions/assets/dist/images/sp-background1.jpg');
  background-attachment: fixed;
  background-position: 30% top;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 100%;

  &:before {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    content: '';
  }
}

.clearfix:after {
  display: table;
  clear: both;
  content: '';
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a {
  color: blue;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:visited {
    color: blue;
  }

  &:hover,
  &:focus {
    color: purple;
    text-decoration: underline;
  }
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
}

.flex-video {
  position: relative;

  // margin-bottom: 0.88889rem;  overflow: hidden;  padding-top: 1.38889rem;  padding-bottom: 67.5%;  height: 0;
  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.wide {
    padding-bottom: 56.34%;
  }
}

form *[data-layout="true"] {
  max-width: none !important;
}

input[type='text'],
input[type='tel'],
input[type='email'],
textarea {
  display: block;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 9px;
  width: 100%;
  border: 1px solid #17468f;
  background: #121c2b;
  color: #ffffff;
  font-weight: 300;
  font-size: 16px;

  &::-webkit-input-placeholder {
    color: #ffffff;
  }

  &::-moz-placeholder {
    color: #ffffff;
  }

  &:-moz-placeholder {
    color: #ffffff;
  }

  &:-ms-input-placeholder {
    color: #ffffff;
  }
}

textarea {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 14px;
  font-style: normal;
  font-size: 16px;
}

label {
  padding-bottom: 5px;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  font-family: $primary-font;
}

select {
  margin-top: 5px;
  padding: 9px;
  width: 100%;
  border: none;
  background: rgba(250, 250, 250, 0.2);
  color: #bfbfbf;
  font-weight: 300;
  font-style: italic;
  font-size: 16px;

  &:focus {
    outline-color: #5798d9;
    background: transparent;
  }
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  padding: 16px 40px;
  // width: calc((100% - 50px) / 1);
  border: none;
  background: #12a8e0;
  color: #091b36;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  font-family: $primary-font;
  cursor: pointer;
  -webkit-appearance: button;

  &:hover {
    background: #cddc28;
    color: #091b36;
  }

  &:focus {
    background: #cddc28 !important;
    color: #091b36 !important;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
}

.content {
  .content-form {
    fieldset {
      padding: 0;
      border: 0;

      >div {
        margin-bottom: 15px;
      }

      h2 {
        padding-bottom: 10px;
      }

      ul {
        list-style-type: none;
      }
    }

    // input[type='text'],
    // textarea {
    //   display: block;
    //   margin-top: 5px;
    //   padding: 9px;
    //   width: 100%;
    //   border: 1px solid #17468f;
    //   background: #121c2b;
    //   color: #ffffff;
    //   font-weight: 300;
    //   font-size: 16px;

    //   &::-webkit-input-placeholder {
    //     color: #ffffff;
    //   }

    //   &::-moz-placeholder {
    //     color: #ffffff;
    //   }

    //   &:-moz-placeholder {
    //     color: #ffffff;
    //   }

    //   &:-ms-input-placeholder {
    //     color: #ffffff;
    //   }
    // }

    // textarea {
    //   margin-top: 10px;
    //   padding: 14px;
    //   font-style: normal;
    //   font-size: 16px;
    // }

    // label {
    //   padding-bottom: 5px;
    //   color: #ffffff;
    //   letter-spacing: 1px;
    //   font-weight: 700;
    //   font-style: normal;
    //   font-size: 16px;
    //   font-family: $primary-font;
    // }

    // select {
    //   margin-top: 5px;
    //   padding: 9px;
    //   width: 100%;
    //   border: none;
    //   background: rgba(250, 250, 250, 0.2);
    //   color: #bfbfbf;
    //   font-weight: 300;
    //   font-style: italic;
    //   font-size: 16px;

    //   &:focus {
    //     outline-color: #5798d9;
    //     background: transparent;
    //   }
    // }

    // button,
    // html input[type='button'],
    // input[type='reset'],
    // input[type='submit'] {
    //   padding: 16px 40px;
    //   width: calc((100% - 50px) / 1);
    //   border: none;
    //   background: #12a8e0;
    //   color: #091b36;
    //   text-transform: uppercase;
    //   font-weight: 700;
    //   font-style: normal;
    //   font-size: 14px;
    //   font-family: $primary-font;
    //   cursor: pointer;

    //   -webkit-appearance: button;

    //   &:hover {
    //     background: #cddc28;
    //     color: #091b36;
    //   }

    //   &:focus {
    //     background: #cddc28 !important;
    //     color: #091b36 !important;
    //   }

    //   @media only screen and (max-width: 1023px) {
    //     width: 100%;
    //   }
    // }

    .hide {
      display: none;
    }

    .col-half {
      float: left;
      padding-right: 24px;
      width: 100%;
      width: calc((100% - 24px) / 2);

      @media only screen and (max-width: 640px) {
        float: none;
        margin-right: 0;
        padding-right: 0;
        width: 100%;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .col-full {
      padding-right: 24px;
      width: calc((100% - 24px) / 1);

      @media only screen and (max-width: 640px) {
        padding-right: 0;
        width: 100%;
      }
    }

    .col-last {
      clear: right;
      margin-right: 0;
    }

    .pnl-textarea {
      margin-top: 9px;
      padding-bottom: 0;

      label {
        position: relative;
        top: 0;
        bottom: auto;
        margin-bottom: 9px;
      }

      .error-msg {
        top: 0;
        bottom: auto;
      }
    }

    .error-msg {
      color: #cddc28;
      right: 0;
      bottom: 0;
    }

    .validation-summary {
      margin-bottom: 28px;

      ul {
        margin: 18px 0;
        list-style: none;
        color: #cddc28 !important;
      }
    }
  }
}

.required {
  label::after {
    position: relative;
    top: -7px;
    margin-left: 2px;
    content: '\f069';
    text-decoration: inherit;
    font-family: FontAwesome;
    font-size: 6px;
    font-weight: normal;
    font-style: normal;
    color: #fff;
  }

  // overides the answers from having the asterik when they are radios and not text inputs , to avoid having the question and every answer have an asterik

  fieldset {
    ul {
      li {
        label {
          &::after{display: none;}
        }
      }
    }
  }
}

.g-recaptcha {
  margin: 0 20px 15px 0;
}

.teaming-conference fieldset ul li {
  list-style-type: none;
}
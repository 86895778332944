.news-search {
  position: relative;
  display: inline-block;
  margin-top: 15px;
  width: 100%;

  input {
    padding: 8px 12px;
    width: 100%;
    border: none;
    border: 1px solid #ddddde;
    border-radius: 1.75rem;
    background: #f8f8f8;
    color: #77777b;
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    font-family: $primary-font;
  }

  a {
    position: absolute;
    top: 0;
    right: 25px;
    border: none;
    background: transparent;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 2px;
      margin-right: 10px;
      color: #17468f;
      content: '\f002';
      font-size: 16px;
      font-family: FontAwesome;
    }
  }
}

.news-listings {
  span.no-speedbump {
    color: #a1aeb2;

    a {
      color: #cddc28;
    }
  }

  .post {
    &:after {
      display: block;
      margin: 2em 0;
      width: 100%;
      height: 0;
      border-bottom: 1px dotted #17468f;
      content: '';
    }

    img {
      display: block;
      float: left;
      margin: 3.5em 30px 0 -60px;
      width: 200px !important;
      height: 200px;
      border: 1px solid #17468f;

      object-fit: cover;

      @media only screen and (max-width: 1023px) {
        margin: 3.5em 30px 0 0;
      }

      @media only screen and (max-width: 640px) {
        display: none;
      }
    }

    .post-content {
      overflow: hidden;
    }

    // p {
    //   a.jump {
    //     display: none;
    //   }
    // }
  }
}

.pubdate,
.author {
  color: #a1aeb2;
}

.news-detail {
  .back-button {
    margin-top: 20px;

    a.back-to {
      &:before {
        padding-right: 5px;
        content: '\f060';
        font-family: FontAwesome;
      }
    }
  }

  .copy {
    img.align-left {
      margin-left: -60px;

      @media only screen and (max-width: 1023px) {
        margin: 10px 30px 1em 0;
        padding: 0 !important;
      }
    }
  }
}

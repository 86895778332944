.search-box-container {
  position: relative;
  display: block;
  margin: 45px 40px;

  @media only screen and (max-width: 1023px) {
    display: none;
  }

  .search-box {
    display: inline-flex;
    width: 100%;

    input {
      width: 163px;
      padding: 8px 12px;
      margin: 0;
      border: none;
      border: 1px solid #ddddde;
      border-radius: 1.75rem;
      background: #f8f8f8;
      color: #77777b;
      font-weight: 500;
      font-style: italic;
      font-size: 12px;
      font-family: $primary-font;

      &::placeholder {
        color: #77777b;
      }
    }

    a {
      position: absolute;
      top: 4px;
      right: 25px;
      padding: 1px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
      color: #17468f;
      text-decoration: none;

      &:hover,
      &:focus {
        color: #77777b;
      }


      &:before {
        position: relative;
        top: 0;
        right: 0;
        margin-right: 10px;
        content: '\f002';
        font-size: 16px;
        font-family: FontAwesome;
      }

      span.search-btn {
        position: absolute;
        top: 32px;
        right: 3px;
        padding-right: 5px;
        color: #000000;
      }
    }
  }
}

.mobile-search-box-container {
  position: relative;
  display: block;
  margin: 45px 40px;

  .mobile-search-box {
    display: inline-flex;
    width: 100%;

    input {
      padding: 8px 12px;
      margin: 0;
      width: 100%;
      border: 1px solid #17468f;
      border-radius: 1.75rem;
      background: #061223;
      color: #ffffff;
      font-style: italic;
      font-size: 12px;
    }

    a {
      position: absolute;
      top: 4px;
      right: 0;
      width: auto;
      padding: 1px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
      text-decoration: none;

      &:before {
        position: relative;
        top: 0;
        right: 0;
        margin-right: 10px;
        color: #80dfff;
        content: '\f002';
        font-size: 16px;
        font-family: FontAwesome;
      }

      &:focus {
        background: transparent !important;
      }

      span.search-btn {
        position: absolute;
        top: 32px;
        right: 3px;
        padding-right: 5px;
        color: #000000;
      }
    }
  }
}

.search-results {
  color: #a1aeb2;

  .search-results-search {
    position: relative;

    a {
      position: absolute;
      top: 0;
      right: 25px;
      border: none;
      background: transparent;
      cursor: pointer;

      &:before {
        position: absolute;
        top: 2px;
        margin-right: 10px;
        color: #17468f;
        content: '\f002';
        font-size: 16px;
        font-family: FontAwesome;
      }
    }

    [type='search'] {
      padding: 8px 12px;
      width: 100%;
      border: none;
      border: 1px solid #ddddde;
      border-radius: 1.75rem;
      background: #f8f8f8;
      color: #77777b;
      font-weight: 500;
      font-style: italic;
      font-size: 12px;
      font-family: $primary-font;
    }

    input.search-submit,
    button {
      border: none;
      background: transparent;
      cursor: pointer;

      // position: absolute;
      // overflow: hidden;
      // clip: rect(0, 0, 0, 0);
      // margin: -1px;
      // padding: 0;
      // width: 1px;
      // height: 1px;
      // border: 0;

      &:after {
        position: relative;
        top: 0;
        right: 0;
        margin-right: 10px;
        color: #80dfff;
        content: '\f002';
        font-size: 16px;
        font-family: FontAwesome;
      }
    }
  }

  table.results {
    thead {

      tr {
        display: block;

        @media only screen and (max-width: 1023px) {
          display: none;
        }
      }

      th {
        text-align: left;
        font-size: 27px;
        color: #80dfff ;

        &:last-of-type {
          display: none;
        }
      }
    }

    tbody {
      padding-top: 20px;
      display: block;

      tr {
        // background: #355871;
        border-bottom: 1px dotted #17468f;

        &:first-of-type {
          td {
            .cell-heading {
              padding-top: 0;
            }
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }

      td {
        // padding: 15px;

        &:last-of-type {
          display: none;
        }

        p {
          margin: 0 0 15px 0;
        }

        .cell-heading {
          display: none;

          @media only screen and (max-width: 1023px) {
            display: block;
            font-size: 14px;
            padding-top: 15px;
          }
        }

        a {
          display: block;
          font-weight: 600;
          padding-top: 15px;

          @media only screen and (max-width: 1023px) {
            padding-top: 0;
          }
        }
      }
    }
  }
}


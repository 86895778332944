.tabs {
  position: relative;
  margin-bottom: 20px;

  &:not(.location-tabs).scroll-left:before,
  &:not(.location-tabs).scroll-right:after {
    position: absolute;
    z-index: 99;
    width: 40px;
    height: 41px;
    content: '';
  }

  &:not(.location-tabs).scroll-left:before {
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(#091b36, 1) 0%, rgba(#091b36, 0) 100%);
  }

  &:not(.location-tabs).scroll-right:after {
    top: 0;
    right: 0;
    background: linear-gradient(to left, rgba(#091b36, 1) 0%, rgba(#091b36, 0) 100%);
  }

  .tab-con {
    position: relative;
    padding: 0;
    margin-bottom: 2px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    -webkit-overflow-scrolling: touch;
  }

  .tab-list {
    @include fontsize(15 17);
    display: none;
    padding: 0;
    margin: 0;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    list-style: none;

    &[role='tablist'] {
      display: inline-block;
    }

    li {
      display: inline-block;
      padding: 0;
      margin: 0 3px 0 0;
      white-space: nowrap;

      @at-root .subpage main.main .content {
        .tab-list li {
          margin: 0 3px 0 0;
          padding: 0;
        }
      }

      &:last-child {
        border-bottom: inherit;
      }

      a {
        position: relative;
        display: block;
        padding: 12px 19px;
        color: #091b36;
        text-align: center;
        text-decoration: none;
        background: #12a8e0;

        @at-root .subpage main.main .content {
          .tab-list a {
            color: #091b36;

            &:hover {
              color: #091b36;
            }

            &[aria-selected] {
              color: #80dfff;
            }
          }
        }

        &:hover,
        &[role='tab']:focus {
          color: #091b36;
          background: #cddc28;
          outline: 0;
        }

        &[aria-selected] {
          padding: 12px 19px;
          color: #80dfff;
          background: #17468f;
          border: 0;

          &:hover,
          &[role='tab']:focus {
            color: #80dfff;
            background: #17468f;
          }
        }
      }
    }
  }

  [role='tabpanel'] {
    &[aria-hidden='true'] {
      display: none;
    }

    .tab-title {
      display: none;
    }
  }

  .tab-content {
    background: #355871;
    padding: 30px 15px 35px;

    p {
      margin-top: 0;
      margin-bottom: 1rem;
      color: #ffffff;
      font-size: 16px;
      line-height: 1.5rem;
    }

    @at-root .subpage main.main .content {
      .tab-content p {
        color: #ffffff;
      }
    }
  }
}
.image-list {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  
  @include medium-up {
    flex-wrap: nowrap;
    flex-grow: 0;
    justify-content: space-between;
  }

  h2,
  h3,
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .image {
    text-align: center;

    @include medium-up {
      display: flex;
      align-content: stretch;
      align-items: stretch;
      overflow: hidden;

      img,
      figure {
        @include medium-up {
          margin: 0;
          object-fit: cover;
          flex: 1;
          min-height: 155px;
        }
      }
    }

    figure {
      img {
        width: 100%;
      }
    }
  }

  @at-root .subpage main.main .content {
    .image-list {
      figure {
        float: none;
        margin: 0;
        padding: 0;

        img {
          border: none;
        }
      }

      .image {
        margin: 0;
        max-width: none;
        width: 100%;

        @include medium-up {
          width: auto;
          margin: 0 30px 0 0;
          width: 220px;
        }
      }

      .content {
        background: transparent;
        padding: 0;
      }
    }
  }

  .content {
    width: 100%;

    @include medium-up {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 230px);
      align-content: center;
    }

    > * {
      width: 100%;
    }
  }
}

.image-list[data-arx-type='layer'] {
  position: relative;
  margin-bottom: 15px;
  outline: 2px dashed rgba(0, 88, 251, 0.2);

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    display: inline-block;
    padding: 2px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    background: rgba(255,255,255,0.5);
    border: 1px dashed rgba(0, 88, 251, 0.2);
    border-top: 0;
    border-right: 0;
    content: 'List With Images';
  }
}
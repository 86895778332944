.footer {
  letter-spacing: 0;

  @media only screen and (max-width: 1023px) {
    display: none;
  }

  ul {
    margin-bottom: 45px;
    margin-left: -10px;
    list-style: none;

    li {
      display: inline-flex;
      padding-right: 90px;
      padding-bottom: 5px;
      font-size: 11px;

      a {
        margin-left: 10px;
        color: #17468f;
        vertical-align: middle;
        text-decoration: none;
        font-weight: 700;
        line-height: 1.0rem;

        &:hover,
        &:focus {
          color: #737378;
        }

        .fa {
          font-size: 18px;
        }
      }
    }
  }

  .usa {
    margin: 0 40px 45px;
  }

  .copy {
    margin: 10px 80px 0 40px;

    p {
      color: #737578;
      font-size: 12px;
      line-height: 1rem;
    }
  }
}

.mobile-footer {
  ul {
    padding: 0 40px;
    list-style: none;

    li {
      display: block;
      padding-bottom: 15px;

      a {
        margin-left: 10px;
        color: #ffffff;
        vertical-align: middle;
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.0rem;

        &:hover {
          color: #77777b;
        }

        .fa {
          font-size: 18px;
        }
      }
    }
  }

  .usa {
    margin: 0 50px;
  }

  .copy {
    margin: 10px 80px 0 50px;

    p {
      color: #ffffff;
      font-size: 12px;
      line-height: 1rem;
    }
  }
}

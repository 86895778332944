.tc-login {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;

  .tc-logo {
    margin: 0 auto;
    max-width: 275px;
    width: 100%;
  }

  .alert.alert-danger {
    color: #cddc28;
  }

  .form-group {
    padding: 5px 0 10px;

    input {
      display: block;
      margin: 0 auto;
      margin-top: 5px;
      padding: 9px;
      width: 100%;
      border: 1px solid #17468f;
      background: #121c2b;
      color: #ffffff;
      font-weight: 300;
      font-size: 16px;
    }

    .button {
      width: 100% !important;

      &:focus {
        background: #cddc28 !important;
        color: #091b36 !important;
      }
    }
  }
}

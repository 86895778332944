.accordions {
  margin-bottom: 20px;

  .accordion-title {
    position: relative;

    @at-root .subpage main.main .content {
      .accordion-title {
        margin-top: 25px;
      }
    }

    button {
      position: relative;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      min-height: 35px;
      padding: 0;
      margin: 0 0 2px 0;
      @include fontsize(15 18);
      font-weight: 700;
      color: #a1aeb2;
      text-align: left;
      text-transform: none;
      background: transparent;
      border: 0;
      border-bottom: 3px solid #12a8e0;
      font-family: $primary-font;
      cursor: pointer;

      span {
        display: none;
      }

      &:after {
        position: absolute;
        top: 0;
        right: 40px;
        width: 15px;
        height: 17px;
        color: #80dfff;
        content: '\f13a';
        text-align: center;
        text-decoration: inherit;
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        font-family: FontAwesome;
        opacity: 0.25;
      }

      &:hover {
        cursor: pointer;
      }

      &:hover,
      &:focus {
        color: white !important;
        background: transparent !important;
        border: 0;
        border-bottom: 3px solid #cddc28;
        outline: 0;

        &:after {
          color: #cddc28;
          opacity: 1;
        }
      }
    }

    &.is-active button,
    &.is-active button:focus {
      color: #80dfff !important;
      background: transparent !important;
      border: 0;
      border-bottom: 3px solid #17468f;
      outline: 0;

      &:after {
        color: #bfbfbf;
        content: '\f139';
        opacity: 0.25;
      }
    }
  }

  .accordion-content[aria-hidden] {
    display: none;
  }

  .accordion-content[aria-hidden='false'] {
    display: block;
  }

  .accordion-content {
    margin: 0 0 5px;
    padding: 30px 15px 35px;
    background: #355871;

    p {
      margin: 0 0 10px;
      color: #ffffff !important;
      font-weight: 400;
      font-size: 16px;
      font-family: $primary-font;
      line-height: 1.5rem;
    }

    ul {
      color: #ffffff !important;
      font-size: 16px;
    }

    @at-root .subpage main.main .content {
      figure {
        margin: 10px 30px 20px 0;
      }
    }
  }

  &.active {
    h2 {
      margin: 0;
    }
  }
}

.homepage {
  main {
    display: inline-block;
    width: 100%;
  }

  .hp-demo {
    position: relative;
    right: 30px;
    float: right;
    clear: both;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 600px;
    background: rgba(0,115,153,0.75);
    font-weight: 400;
    font-style: normal;
    font-family: $primary-font;

    @media only screen and (max-width: 1023px) {
      right: 0;
      z-index: 0;
      float: none;
      margin: 200px auto 30px;
      width: 600px;
    }

    @media only screen and (max-width: 680px) {
      float: none;
      margin-top: 130px;
      width: 100%;
    }

    .hp-demo-content {
      padding: 30px 60px 30px 30px;
      color: #ffffff;
      line-height: 24px;

      @media only screen and (max-width: 1023px) {
        padding: 50px 25px 30px;
      }

      h1 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 22px;
      }

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }

      a {
        color: #cddc28;
        text-decoration: none;

        &:hover {
          color: #ffffff;
        }
      }

      img {
        // @media only screen and (max-width: 680px) {
        //   display: none;
        // }
      }
    }

    .demo-request {
      display: block;
      padding: 17px 0;
      background: #12a8e0;
      color: #091b36;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;

      &:after {
        margin-left: 5px;
        content: '\f0a9';
        font-size: 15px;
        font-family: FontAwesome;
      }

      &:hover,
      &:focus {
        background: #cddc28;
      }
    }
  }

  .hp-news {
    position: relative;
    right: 30px;
    float: right;
    clear: both;
    width: 600px;
    background: rgba(9, 27, 54, 0.75);
    font-weight: 400;
    font-style: normal;
    font-family: $primary-font;

    @media only screen and (max-width: 1023px) {
      right: 0;
      z-index: 0;
      float: none;
      margin: 0 auto;
      width: 600px;
    }

    @media only screen and (max-width: 680px) {
      right: 0;
      z-index: 0;
      float: none;
      margin-top: -30px;
      width: 100%;
    }

    .hp-news-content {
      padding: 30px 25px;
      width: 80%;
      color: #ffffff;
      line-height: 24px;

      &:hover,
      &:focus {
        a.read-more {
          color: #ffffff;
        }
      }

      h2 {
        color: #80dfff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 22px;
      }

      p {
        font-size: 16px;
      }

      a.read-more {
        color: #cddc28;
        text-decoration: none;
      }
    }

    .all-news {
      display: block;
      clear: both;
      padding: 17px 0;
      background: #17468f;
      color: #80dfff;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;

      &:after {
        margin-left: 5px;
        content: '\f0a9';
        font-size: 15px;
        font-family: FontAwesome;
      }

      &:hover,
      &:focus {
        background: #cddc28;
        color: #091b36;
      }
    }

    .pager {
      position: absolute;
      right: 25px;
      bottom: 50%;
      display: inline-flex;
      float: right;
      clear: both;
      padding: 0 45px;
      width: 20%;
      color: #80dfff;
      font-size: 26px;

      .left {
        margin-right: 10px;
        cursor: pointer;
        opacity: 0.25;


        &:before {
          content: '\f137';
          font-family: FontAwesome;
        }

        &:hover,
        &:focus {
          color: #cddc28;
          opacity: 1;
        }
      }

      .right {
        margin-left: 10px;
        cursor: pointer;
        opacity: 0.25;


        &:before {
          content: '\f138';
          font-family: FontAwesome;
        }

        &:hover,
        &:focus { 
          color: #cddc28;
          opacity: 1;
        }
      }
    }
  }
}

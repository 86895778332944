.quick-contact {
  margin-top: 45px;
  border-top: 1px dotted #17468f;

  h2 {
    margin-top: 45px !important;
    margin-bottom: 0;
    color: #80dfff !important;
    text-transform: uppercase !important;
    font-size: 20px;
  }

  .multi-column.contact {
    margin-top: -15px;
    width: 100%;

    @media only screen and (max-width: 1200px) {
      display: block;
    }

    .column.location {
      margin-top: 20px;
      width: 50%;

      @media only screen and (max-width: 1024px) {
        width: 100%;
      }

      h3 {
        margin-bottom: 0;
        padding: 0;
        color: #ffffff;
        text-transform: none;
        font-size: 18px;
      }
    }
  }
}

.subpage {
  font-style: normal;
  font-family: $primary-font;

  section.main,
  main.main {
    padding: 1.875rem 1.875rem 11.25rem 26.4375rem;
    max-width: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875rem;

    @media only screen and (max-width: 1023px) {
      margin-top: 115px;
      padding: 0;
    }

    .page-heading {
      padding: 1.875rem 0 1.875rem 1.875rem;
      background: rgba(0, 127, 173, 0.8);
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 100;

      @media only screen and (max-width: 1023px) {
        padding: 3.438rem 1.25rem 1.25rem;
      }

      h1 {
        margin: 0;
        font-weight: 100;
        font-style: normal;
        font-size: 42px;
        font-family: $primary-font;
        line-height: 40px;

        // @media only screen and (max-width: 1023px) {
        //   font-size: 36px;
        // }

        @media only screen and (max-width: 1023px) {
          text-indent: -9999999px;
        }

        &:before {
          position: relative;
          top: -4px;
          float: right;
          margin: 0 20px;
          width: 185px;
          height: 50px;
          background: url('/Themes/RetirementSolutions/assets/dist/images/PensionGold-Logo.svg') no-repeat;
          content: '';
          vertical-align: middle;

          // @media only screen and (max-width: 1023px) {
          //   display: none;
          // }

          @media only screen and (max-width: 1023px) {
            display: block !important;
            float: left !important;
          }
        }
      }
    }

    .tag-line {
      height: 30px;
      background: rgba(128, 223, 255, 0.8);
      color: #091b36;
      text-transform: uppercase;
      font-weight: 600;

      p {
        margin: 0;
        padding: 0 1.875rem;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .content {
      margin-top: 15px;
      padding: 1rem 5.625rem 7.5rem 1.875rem;
      max-width: 100%;
      width: 100%;
      background: rgba(9, 27, 54, 0.8);
      content: '';
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.875rem;

      @media only screen and (max-width: 1023px) {
        margin-top: 0;
        padding: 0.85rem 1.25rem 11.25rem;
      }

      h1 {
        color: #ffffff;
        text-transform: uppercase;
      }

      h2 {
        margin-top: 45px;
        color: #ffffff;
        text-transform: uppercase;
      }

      h3 {
        color: #80dfff;
        text-transform: uppercase;
      }

      h4 {
        color: #ffffff;
      }

      p {
        color: #a1aeb2;
      }

      a {
        color: #cddc28;
        text-decoration: none;

        &:hover,
        &:focus {
          color: #ffffff;
        }
      }

      .button {
        padding: 16px 40px;
        width: calc((100% - 50px) / 1);
        border: none;
        background: #12a8e0;
        color: #091b36;
        text-transform: uppercase;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        font-family: $primary-font;
        cursor: pointer;

        -webkit-appearance: none;

        &:hover {
          background: #cddc28;
          color: #091b36;
        }

        @media only screen and (max-width: 1023px) {
          width: 100%;
        }
      }

      ul {
        margin-top: 0;
        margin-left: -10px;
        padding-left: 0;
        color: #a1aeb2;

        li {
          position: relative;
          margin: 0 0 0 1.5em;
          padding: 4px;

          // list-style: none;

          // &:before {
          //   position: absolute;
          //   top: 0;
          //   left: -1.5em;
          //   box-sizing: border-box;
          //   margin-right: 8px;
          //   padding: 4px;
          //   width: 2em;
          //   color: #ffffff;
          //   content: '';
          //   text-align: center;
          //   font-weight: 900;
          //   font-style: normal;
          //   font-size: 24px;
          // }
        }

        ul {
          li:before {
            color: #ffffff;
            content: '';
          }
        }
      }

      ol {
        margin-top: 0;
        margin-left: -10px;
        padding-left: 0;
        color: #a1aeb2;
        counter-reset: li;

        li {
          position: relative;
          margin: 0 0 0 1.5em;
          padding: 4px;
          list-style: none;

          &:before {
            position: absolute;
            top: 0;
            left: -1.5em;
            box-sizing: border-box;
            margin-right: 8px;
            padding: 4px;
            width: 2em;
            color: #ffffff;
            content: counter(li);
            counter-increment: li;
            text-align: center;
            font-weight: 900;
            font-style: normal;
            font-size: 18px;
          }
        }
      }

      ol > li > ol > li:before {
        color: #ffffff;
        content: '•';
      }

      li ol,
      li ul {
        margin: 0 10px;
      }

      ul ul li,
      ol ol li:last-child {
        margin-bottom: -5px;
        margin-left: 15px;
      }

      figure {
        margin: 10px 30px 1em -60px;

        @media only screen and (max-width: 1023px) {
          margin: 10px 30px 1em 0;
        }

        @media only screen and (max-width: 680px) {
          margin: 0 auto 30px;
          width: 100%;

          img {
            width: 100%;
          }
        }

        figcaption {
          margin-top: -7px;
          padding: 15px 30px;
          background: #17468f;
          color: #ffffff;
          font-weight: 300;
          font-style: italic;
          font-size: 12px;
          font-family: $primary-font;
        }
      }

      blockquote {
        float: left;
        margin: 10px 30px 1em -60px;
        padding: 30px 30px 38px;
        max-width: 300px;
        width: 100%;
        background: #17468f;
        color: #80dfff;
        font-weight: 300;
        font-style: italic;
        font-size: 20px;
        font-family: $primary-font;
        line-height: 24px;

        @media only screen and (max-width: 1023px) {
          margin: 10px 30px 1em 0;
        }

        @media only screen and (max-width: 680px) {
          margin: 0 auto 30px;
          max-width: 100%;
        }

        &:before {
          margin-left: -10px;
          content: open-quote;
        }

        &:after {
          content: close-quote;
        }
      }

      .no-quote {
        float: left;
        margin: 8px 30px 1em -60px;
        padding: 30px 30px 38px;
        max-width: 300px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin: 10px 30px 1em 0;
        }

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      .infographic {
        background: none;
        float: left;
        margin: 45px 30px 1em -60px;
        padding: 0;
        max-width: 100%;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin: 10px 30px 1em 0;
        }

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      .image {
        background: none;
        float: left;
        margin: 45px 30px 1em -60px;
        padding: 0;
        max-width: 300px;
        width: 100%;

        @media only screen and (max-width: 1023px) {
          margin: 10px 30px 1em 0;
        }

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      img {
        max-width: 100%;
      }

      img.align-left {
        float: left;
        // padding: 10px 20px 10px 0;
      }

      img.align-right {
        float: right;
        // padding: 10px 0 10px 20px;
      }

      .spacer {
        clear: both;
        margin: 2em 0;
        width: 100%;
        height: 0;
        border-bottom: 1px dotted #17468f;
        content: '';
      }
    }
  }
}

// .subpage.software {
//   .page-heading {
//     h1 {
//       @media only screen and (max-width: 1023px) {
//         text-indent: -9999999px;
//       }

//       &:before {
//         @media only screen and (max-width: 1023px) {
//           display: block !important;
//           float: left !important;
//         }
//       }
//     }
//   }
// }

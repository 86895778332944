.accordions {
  margin-bottom: 20px;

  .accordion-title {
    position: relative;

    button {
      @include fontsize(15 18);
      position: relative;
      width: 100%;
      padding: 20px 82px 10px 0;
      margin: 0 0 10px 0;
      font-weight: 700;
      color: black;
      text-align: left;
      background: transparent;
      border: 0;
      border-bottom: 2px solid gray;
      span {
        position: absolute;
        right: 0;
        bottom: -2px;
        width: 80px;
        height: calc(100% + 2px);
        border-bottom: 2px solid gray;

        &:after {
          position: absolute;
          top: 50%;
          left: 32px;
          font-family: FontAwesome;
          font-size: 18px;
          font-style: normal;
          font-weight: normal;
          color: black;
          text-align: center;
          text-decoration: inherit;
          content: '\f067';
          transform: translateY(-8px);
        }
      }

      &:hover {
        cursor: pointer;
      }

      &:hover,
      &:focus {
        color: blue;
        background: transparent;
        border: 0;
        border-bottom: 2px solid gray;
        outline: 0;

        span {
          border-bottom: 2px solid gray;
        }
      }
    }

    &.is-active button,
    &.is-active button:focus {
      color: blue;
      background: transparent;
      border: 0;
      border-bottom: 2px solid gray;
      outline: 0;

      span {
        border-bottom: 2px solid gray;

        &:after {
          content: '\f068';
        }
      }
    }
  }

  .accordion-content[aria-hidden] {
    display: none;
  }

  .accordion-content[aria-hidden='false'] {
    display: block;
  }

  &.active {
    h2 {
      margin: 0;
    }
  }
}

.accordion[data-arx-type='layer'] {
  position: relative;
  margin-bottom: 15PX;
  background: repeating-linear-gradient(to right,rgba(0,88,251,0.04),rgba(0,88,251,0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)),transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)),transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0,88,251,0.2);

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2PX;
    font-size: 12PX;
    font-weight: bold;
    text-transform: uppercase;
    background: rgba(0,88,251,0.04);
    border: 1px dashed rgba(0,88,251,0.2);
    border-top: 0;
    border-right: 0;
    content: 'Accordion';
  }
}
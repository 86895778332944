.cta-card {
  border-color: #17468f;
  
  @at-root .subpage main.main .content {
    .cta-card {
      figure {
        float: none;
        width: calc(100% + 72px);
        margin: 0;
        padding: 0;

        img {
          border: none;
        }
      }
    }
  }
}

.cta-image {
  @at-root .subpage main.main .content {
    .cta-image {
      figure {
        float: none;
        margin: 0;
        padding: 0;

        img {
          border: none;
        }
      }

      .content {
        background: transparent;
        padding: 0;
      }
    }
  }

  .img {
    &::after {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.8) 100%);
    }
  }
}

.cta-billboard {
  min-height: 0px;
  padding: 0;

  @at-root .subpage main.main .content {
    .cta-billboard {
      figure {
        float: none;
        margin: 0;
        padding: 0;

        img {
          border: none;
        }
      }

      .content {
        background: transparent;
        padding: 0;
      }

      p {
        color: white;
      }
    }
  }

  .img {
    left: auto;
    width: auto;
    max-width: auto;

    &::after {
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }
  }
}

.cta-banner {
  background: #17468f;

  @at-root .subpage main.main .content {
    .cta-banner {
      .content {
        background: transparent;
        padding: 0;
      }

      p {
        color: white;
      }
    }
  }

}

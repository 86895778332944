// general
.element-invisible {
  @include element-invisible;
}

.sr-only {
  @include sr-only;
}

.offscreen {
  @include offscreen;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: '';
}

// floats
.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

// alignment
.text-left,
.align-left {
  text-align: left;
}

.text-right,
.align-right {
  text-align: right;
}

.text-center,
.align-center {
  text-align: center;
}

.text-justify,
.align-justify {
  text-align: justify;
}

// visiblity classes
.show-for-landscape {
  @include landscape {
    display: block;
  }
  @include portrait {
    display: none;
  }
}

.show-for-portrait {
  @include portrait {
    display: block;
  }
  @include landscape {
    display: none;
  }
}

.hide-for-landscape {
  @include landscape {
    display: none;
  }
  @include portrait {
    display: block;
  }
}

.hide-for-portrait {
  @include portrait {
    display: none;
  }
  @include landscape {
    display: block;
  }
}

// show only
.show-for-small-only {
  @include medium-up {
    display: none;
  }
}

.show-for-medium-only {
  @include small-only {
    display: none;
  }
  @include large-up {
    display: none;
  }
}

.show-for-large-only {
  display: none;
  @include large-only {
    display: block;
  }
  @include xlarge-up {
    display: none;
  }
}

.show-for-xlarge-only {
  display: none;
  @include xlarge-only {
    display: block;
  }
  @include xxlarge-up {
    display: none;
  }
}

.show-for-xxlarge-only {
  display: none;
  @include xxlarge-up {
    display: none;
  }
}

// show up
.show-for-medium-up {
  display: none;
  @include medium-up {
    display: block;
  }
}

.show-for-large-up {
  display: none;
  @include large-up {
    display: block;
  }
}

.show-for-xlarge-up {
  display: none;
  @include xlarge-up {
    display: block;
  }
}

.show-for-xxlarge-up {
  display: none;
  @include xxlarge-up {
    display: block;
  }
}

// hide only
.hide-for-small-only {
  @include small-only {
    display: none;
  }
}

.hide-for-medium-only {
  @include medium-only {
    display: none;
  }
}

.hide-for-large-only {
  @include large-only {
    display: none;
  }
}

.hide-for-xlarge-only {
  @include xlarge-only {
    display: none;
  }
}

.hide-for-xxlarge-only {
  @include xxlarge-up {
    display: none;
  }
}

// hide up
.hide-for-medium-up {
  @include medium-up {
    display: none;
  }
}

.hide-for-large-up {
  @include large-up {
    display: none;
  }
}

.hide-for-xlarge-up {
  @include xlarge-up {
    display: none;
  }
}

.hide-for-xxlarge-up {
  @include xxlarge-up {
    display: none;
  }
}
.testimonial {
  position: relative;
  right: 30px;
  clear: both;

// width: 600px;
  height: auto;

// border-bottom: 1px dotted #17468f;
  font-weight: 400;
  font-style: normal;
  font-family: $primary-font;

  @media only screen and (max-width: 1023px) {
    right: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    .pager {
      color: #cddc28;
      opacity: 1;
    }
  }

  .testimonial-content {
    padding: 0 25px 30px;

// width: 80%;
    width: 100%;
    color: #ffffff;
    line-height: 24px;

    &:hover,
    &:focus {
      a.read-more {
        color: #ffffff;
      }
    }

    @media only screen and (max-width: 1023px) {
      padding: 0;
    }

    .details .image {
      float: left;
      margin: 0 30px 1em -60px !important;
      width: auto !important;

      @media only screen and (max-width: 1023px) {
        display: none;
      }

      img {
        max-width: 180px !important;
        width: 100%;
        border: 1px solid #17468f;
      }
    }

    .detail-info {
      overflow: hidden;

      .quote {
        margin-bottom: 24px;
        color: #80dfff;
        font-weight: 300;
        font-style: italic;
        font-size: 20px;

        // &:before {
        //   margin-left: -10px;
        //   content: open-quote;
        // }

        // &:after {
        //   content: close-quote;
        // }
      }

      .name-title {
        font-size: 16px;
      }

      .company {
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    .pager {
      position: relative;

// top: 20px;
      bottom: -15px;
      z-index: 101;
      display: block;
      height: 50px;
      color: #80dfff;
      font-size: 26px;
      @media only screen and (max-width: 1023px) {
        // left: 25px;
        // float: left;
      }

      .back {
        display: inline-block;
        margin-right: 5px;
        cursor: pointer;

        &:before {
          content: '\f137';
          font-family: FontAwesome;
          opacity: 0.25;
        }

        &:hover,
        &:focus {
          &:before {
            color: #cddc28;
            opacity: 1;
          }
        }
      }

      .next {
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;

        &:before {
          content: '\f138';
          font-family: FontAwesome;
          opacity: 0.25;
        }

        &:hover,
        &:focus {
          &:before {
            color: #cddc28;
            opacity: 1;
          }
        }
      }
    }
  }
}

.subpage main.main .content {

  margin: 0 auto;

  .support-item {
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 80px;
    width: 100%;
    color: #a1aeb2;
    vertical-align: top;
    text-align: center;
    margin-bottom: -50px;

    // @media only screen and (max-width: 767px) {
    //   width: 49%;
    // }

    figure {
      float: none;
    }

    img {
      margin: 0 auto;
      max-height: 60px !important;
      border: none;
      width: auto;
    }

    span {
      display: block;
    }
    span.secondary {
      font-size: 12px;
      font-weight: 700;
      color: #ffffff;
      line-height: 18px;
    }
  }
}

$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';
// colors

$table-border: #1c2b42;
$table-head-text: #80dfff;
$table-head-background: #17468f;
$table-head-border: #1c2b42;

$primary-font: 'Red Hat Text', sans-serif;

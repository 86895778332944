#clickdimensionsForm {
  margin: auto !important;
  max-width: 100% !important;
  // padding-right: 20px !important;

  @media (max-width:768px) {
    max-width: 100% !important;
  }

  tr:nth-child(odd) {
    background-color: transparent !important;
  }
}

.responsiveRow {
  // display: flex;
  // min-height: 50px;
  display: block;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
}

.emptyCell {
  display: none;
}

.responsiveCell {
  text-align: left !important;
  
  div.alignBottom,
  div.alignTop {
    display: inline-block !important;
  }
}

.responsiveCellSize1 {
  width: 100% !important;
  // min-width: 50% !important;
  flex: 1;
  margin: 0 20px 0 0 !important;

  @media only screen and (min-width: 768px) {
    width: 50% !important;
  }

  span {
    font-family: inherit !important;
  }
}

.responsiveCellSize2 {
  width: 100%;
  min-width: 0;
  margin:  0 10px;
}

.clickdform {
  font-family: inherit;

  &.mainDiv {
    float: none !important;
    padding-left: 0 !important;
  }

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  input[type='radio'],
  textarea,
  select {
    font-size: 1rem !important;
    padding: .5rem !important;
    border-radius: 0 !important;
    border: 1px solid #17468f !important;
    background: #121c2b !important;
    box-shadow: inset 0 1px 2px rgba(10,10,10,.1) !important;
    // min-height: 0px !important;
    height: 2.4375rem !important;
    margin: 0 0 1rem !important;
    font-family: inherit !important;
    transition: box-shadow .5s,border-color .25s ease-in-out !important;
    color: #ffffff !important;

    &[disabled] {
      border-color: palette(gray, 6);
      background: palette(gray, 6);
    }
  }

  textarea {
    min-height: 120px !important;
  }

  input[type='checkbox'],
  input[type='radio'] {
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
    margin: 9px 10px 0 0 !important;
    transition: none !important;
    height: auto !important;
  }

  .buttonContainer {
    width: 100% !important;
  }

  #btnSubmit {
    background: #12a8e0 !important;
    color: #091b36 !important;
    border: none !important;
    padding: 16px 40px !important;
    border-radius: 0 !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    cursor: pointer !important;
    display: block !important;
    line-height: 1 !important;
    text-align: centeer !important;
    transition: background-color .25s ease-out,color .25s ease-out !important;
    vertical-align: middle !important;
    margin: 0 0 1rem 0 !important;
    font-family: proxima-nova, sans-serif !important;
    letter-spacing: .0625rem !important;
    width: 100% !important;

    &:active,
    &:hover,
    &:focus {
      background: #cddc28 !important;
      color: #091b36 !important;
      text-decoration: none !important;
    }
  }

  .minSize1,
  .minSize2,
  .maxSize1,
  .maxSize2,
  .minSize3 {
    width:100% !important;
    min-width:100%  !important;
    font-size: 16px !important;
    font-family: inherit !important;
    // min-height: 40px !important;
    color: #ffffff !important;

    b,
    strong,
    span {
      font-weight: 700 !important;
      font-family: inherit !important;
      letter-spacing: 1px !important;
      margin-bottom: 5px !important;
      color: #ffffff !important;
    }
  }

  .minSize3 p,
  .maxSize3 p {
    color: #a1aeb2;
    font-weight: normal;
  }


  .emptyCell {
    display: none !important;
  }

}

a {
  &.btn {
    color: white;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    line-height: initial;
    transition: all .33s ease-in-out;

    &:hover,
    &:focus {
      text-decoration: none;
      color: white;
    }

    &:visited {
      color: white;
    }

    &.btn-primary {
      color: #091b36;
      background: #12a8e0;
      border: 2px solid #12a8e0;

      @at-root .subpage main.main .content {
        a.btn.btn-primary {
          color: #091b36;

          &:hover,
          &:focus {
            color: #091b36;
          }
        }
      }

      &:visited {
        color: #091b36;
        background: #12a8e0;
        border: 2px solid #12a8e0;
      }

      &:hover,
      &:focus {
        color: #091b36;
        background: #cddc28;
        text-decoration: none;
        border-color: #cddc28;
      }
    }

    &.btn-secondary {
      color: #80dfff;
      background: #17468f;
      border: 2px solid #17468f;

      @at-root .subpage main.main .content {
        a.btn.btn-secondary {
          color: #80dfff;

          &:hover,
          &:focus {
            color: #091b36;
          }
        }
      }

      &:visited {
        color: #80dfff;
        background: #17468f;
        border: 2px solid #17468f;
      }

      &:hover,
      &:focus {
        color: #091b36;
        background: #cddc28;
        text-decoration: none;
        border-color: #cddc28;
      }
      
    }
  }
}
// Splide

.splide {
  overflow: hidden;

  @media all and (min-width:1230px) {
    padding: 50px;
  }

  &__track {
    @media all and (min-width:1230px) {
      overflow: visible;
    }
  }

  &__list {
    max-height: 225px;

    @media all and (min-width:1024px) {
        max-height: 290px;

    }

    @media all and (min-width:1230px) {
      max-height: none;

    }
  }

  &__slide {
    display: flex;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 9;

    img {
      height: 100%;

      @media all and (min-width: 1400px){
        transform: scale(1.1);
        transition: all .3s linear;
      }
    }

    &:not(.is-active.is-visible) {
      z-index: 1;
      img {
        @media all and (min-width: 1400px){
          opacity: 50% !important;
          transform: translate(0,0);
        }
      }
    }

  } 

  &__arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    @media all and (min-width: 1400px){
      margin: 50px 0 0;

    }
  }

  &__arrow {
    display: block;
    height: auto;
    width: auto;
    background: transparent;
    position: relative;
    color: #80dfff;
    text-transform: none;
    top: auto;
    transform: none;
    font-size: 18px;

    &:hover,
    &:focus {
      background: transparent !important;
      color: white !important;
    }

    &--prev {
      @media all and (min-width:1230px) {
        left: -50px;
      }
    }

    &--next {
      @media all and (min-width:1230px) {
        right: -50px;
      }
    }
  }
}

.slide-counter {
  color: white;
  text-align: center;
  margin-top: -56px;


  @media all and (min-width: 1400px){
    margin-top: -26px;
  }
}
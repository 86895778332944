.sidebar {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  max-width: 273px;
  width: 100%;
  letter-spacing: 2px;
  font-weight: 500;
  font-style: normal;
  font-family: $primary-font;

  @media only screen and (max-width: 1023px) {
    top: 0;
    z-index: 2;
    min-height: 196px;
    max-width: 100%;
  }

  &::before {
    position: absolute;
    z-index: 0;
    min-height: 100%;
    max-width: 273px;
    width: 100%;
    background: #ffffff;
    content: '';
    transform: skewX(-3.2deg);
    transform-origin: top right;

    @media only screen and (max-width: 1023px) {
      position: absolute;
      top: 0;
      z-index: 0;
      min-height: 165px;
      max-width: 100%;
      width: 100%;
      transform: skewY(-1.7deg);
      transform-origin: bottom left;
    }
  }

  .header {
    position: relative;

    // @media only screen and (max-width: 1023px) {
    //   transform: skewY(0.75deg);
    // }
  }

  .primary-nav {
    display: block;
    margin-left: 30px;

    @media only screen and (max-width: 1023px) {
      display: none;
    }

    .ul1 {
      margin: 165px 0 0 -40px;
      width: 100%;
      list-style: none;

      .li1 {
        display: block;
        padding: 6px 0 7px 30px;
        width: 250px;
        color: #17468f;

        &:before {
          display: none;
        }

        &:hover,
        &:focus {
          background: #e4e4e5;
          color: #091b36;

          .a1:after {
            position: absolute;
            top: -10px;
            left: 210px;
            display: block;
            width: 0;
            height: 0;
            border-top: solid 16px transparent;
            border-right: solid 16px transparent;
            border-bottom: solid 16px transparent;
            border-left: solid 16px #e4e4e5;
            content: '';
          }

          .ul2 {
            left: 300px;
            display: block;
            margin-top: -15px;
            padding-left: 30px;
            width: 100%;
          }
        }

        .a1 {
          position: relative;
          display: inline-block;
          margin-left: 10px;
          color: #646468;
          vertical-align: middle;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 11px;

          &:before {
            position: absolute;
            top: -2px;
            margin-left: -30px;
            color: #17468f;
            font-size: 15px;
            font-family: FontAwesome;
          }
        }
      }

      .li1#about-us .a1:before {
        content: '\f2bd';
      }

      .li1#software .a1:before {
        content: '\f108';
      }

      .li1#implementation .a1:before {
        content: '\f013';
      }

      .li1#support .a1:before {
        content: '\f095';
      }

      .li1#news .a1:before {
        content: '\f1ea';
      }

      .li1#testimonials .a1:before {
        content: '\f10d';
      }
    }

    .ul2 {
      position: absolute;
      z-index: -1;
      display: none;
      margin: 0;
      list-style: none;

      &:before {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        min-height: 100vh;
        width: 670px;
        background: linear-gradient(to right, rgba(9, 27, 54, 0.9) 65%, rgba(9, 27, 54, 0.9) 65%, rgba(8, 25, 54, 0.6) 90%, rgba(0, 0, 85, 0.01) 99%, rgba(0, 0, 0, 0.01) 100%);
        content: '';
      }
    }

    .li2 {
      position: relative;

// top: -15px;
      // left: 300px;
      display: block;
      padding: 0 0 18px;
      text-transform: uppercase;
      font-size: 11px;
    }

    .a2 {
      position: relative;
      display: block;
      margin: 0;
      color: #cddc28;
      text-decoration: none;
      font-family: inherit;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        height: 100%;
        background: blue;
        content: '';
      }

      &:hover,
      &:focus {
        color: #ffffff;

        &:before {
          display: block;
        }
      }
    }
  }

  .primary-nav ul.ul1 > li.focused .ul2 {
    left: 300px;
    display: block;
    margin-top: -15px;
    padding-left: 30px;
    width: 100%;
  }
}

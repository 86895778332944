/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';

// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Vendor
@import 'vendor/normalize';
@import 'vendor/article-grid';
@import 'vendor/article-columns';
@import 'vendor/images';
@import 'vendor/cta';
@import 'vendor/modal';
@import 'vendor/tables';
@import 'vendor/accordions';
@import 'vendor/tabs';
@import 'vendor/splide';
@import 'vendor/splide-core';

// Layout
@import 'layout/header';
@import 'layout/footer';
@import 'layout/content';
// Components
@import 'components/general';
@import 'components/typography';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/lrs-corp-bar';
@import 'components/search';
@import 'components/mobile-menu';
@import 'components/accessibility';
@import 'components/subpage';
@import 'components/columns';
@import 'components/tables';
@import 'components/accordions';
@import 'components/tabs';
@import 'components/testimonials';
// @import 'components/tablesaw';
@import 'components/forms';
@import 'components/news';
@import 'components/homepage';
@import 'components/quick-contact';
@import 'components/employment';
@import 'components/tc-login';
@import 'components/support';
@import 'components/clickd';
@import 'components/cta';
@import 'components/highlighted-text';
@import 'components/image-lists';
@import 'components/buttons';
@import 'components/modal';
@import 'components/photo-gallery-slider';


// @import 'components/iebar';
// @import 'components/dnn';
// Vendor
// Extras
@import 'components/shame';
.multi-list,
.multi-column {
  display: inline-flex;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    display: block;
    margin-left: -15px;
    width: 100%;

    p:nth-child(even) {
      border-right: none;
      border-left: none;
    }
  }

  .lists,
  .column {
    padding: 0 30px 10px 0;
    width: 100%;

    @media only screen and (max-width: 1024px) {
      padding: 0 15px;
    }

    h3 {
      margin-top: 0;
      padding-bottom: 7px;
    }

    img {
      width: 100%;
    }

    p {
      margin: 0 0 1em 0;
    }

    ul {
      margin: 0;
      width: 100%;

      // list-style: none;

      li {
        margin-left: -40px;
        padding: 7px 0;
        font-weight: 500;
        font-style: normal;
        font-family: $primary-font;
        line-height: 22px;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    span {
      color: #ffffff;
      font-weight: 600;
    }
  }
}

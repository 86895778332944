.rs-logo {
  margin: 40px 30px;
  width: 214px;

  @media only screen and (max-width: 1023px) {
    position: relative;
    top: 30px;
    z-index: 2;
    margin: 40px 20px;
  }

  a {
    display: block;
    width: 214px;
    height: 76px;
    background: url('/Themes/RetirementSolutions/assets/dist/images/lrs-retirement-solutions-logo-reg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    text-indent: -10000rem;

    @media only screen and (max-width: 1023px) {
      margin: 0 15px 0 0;
      width: 179px;
      height: 63px;
    }
  }
}

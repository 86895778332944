.two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
  margin: 0;
  padding: 0 0 25px;
  list-style: none;
  @media only screen and (min-width: 768px) {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .col,
  li.col {
    width: 100%;
    margin: 0 !important;
    padding-left: 0 !important;
  }

  li.col {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.two-column .col,
ul.two-column li.col {
  @media only screen and (min-width: 768px) {

    // padding: 0 40px 25px 25px;
    // width: 50%;
    width: calc(50% - 30px);
  }
}

.three-column .col,
ul.three-column li.col {
  @media only screen and (min-width: 768px) {

    // padding: 0 40px 25px 25px;
    // width: 50%;
    width: calc(50% - 30px);
  }

  @media only screen and (min-width: 992px) {    // width: 33%;
    width: calc(33% - 30px);
  }
}

.four-column .col,
ul.four-column li.col {
  @media only screen and (min-width: 768px) {
    // padding: 0 40px 25px 25px;
    // width: 50%;

    width: calc(50% - 30px);

    p {
      font-size: 16px !important;
      line-height: 22px !important;
    }
  }
  @media only screen and (min-width: 992px) {
    // width: 25%;
    width: calc(25% - 30px);
  }
}

.five-column .col,
ul.five-column li.col {
  @media only screen and (min-width: 768px) {

    // padding: 0 20px 25px 25px;
    // width: 50%;
    width: calc(50% - 30px);

    p {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
  @media only screen and (min-width: 992px) {
    // width: 20%;
    width: calc(20% - 30px);
  }
}

.six-column .col,
ul.six-column li.col {
  @media only screen and (min-width: 768px) {

    // padding: 0 20px 25px 25px;
    // width: 50%;
    width: calc(50% - 30px);

    p {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
  @media only screen and (min-width: 992px) {
    // width: 20%;
    width: calc(20% - 30px);
  }
  @media only screen and (min-width: 1200px) {

    // width: 16.66666666666667%;
    width: calc(16.66666666666667% - 30px);
  }
}